var app;
(function (app) {
    var rxData;
    (function (rxData) {
        'use strict';
        /**
         * links
         *        //https://xngiser.gitbooks.io/rxjs/content/api/subjects/asyncsubject.html
                //https://github.com/Reactive-Extensions/RxJS/blob/master/doc/api/core/observer.md
                //https://github.com/Reactive-Extensions/RxJS/blob/master/doc/api/core/operators/asobservable.md
    
         */
        /**
         * we manage our data delivery to every state/page from here, on each event that passes via subscription almost like switch case, we distribute the date, and we assertData the object
         * which then is send and ready on each "stateChangeSuccess",
         * data is available from rxData.rootScopeOnready().then ... when calliing on each state.
         */
        var RXData = /** @class */ (function () {
            /* @ngInject */
            RXData.$inject = ["$timeout", "dataservice", "$q", "$rootScope", "errorHandler"];
            function RXData($timeout, dataservice, $q, $rootScope, errorHandler) {
                this.$timeout = $timeout;
                this.dataservice = dataservice;
                this.$q = $q;
                this.$rootScope = $rootScope;
                this.errorHandler = errorHandler;
                this.defer = this.$q.defer();
                // init constructor;
                this.init();
            }
            /// the init is required to reinitialize the promise from stateChangeStart on  app.core .run()
            RXData.prototype.init = function () {
                this.defer = this.$q.defer();
                // console.log('init constructor??')
            };
            RXData.prototype.currentPageData = function () {
                // return this.deferCurrentData.promise;
            };
            RXData.prototype.assertData = function (hasSubs, data, stateName, observer, pageOrgName) {
                var currPageDATA = false;
                if (_.isObject(data) && data.PAGES !== undefined) {
                    if (hasSubs != false) {
                        currPageDATA = data.PAGES[hasSubs.main][hasSubs.rel];
                        currPageDATA.CTA = data.CTA;
                        /// safari dont like undefined so we set condition
                        if (data["image-descriptions"] !== undefined && pageOrgName !== false) {
                            if (data["image-descriptions"][pageOrgName] !== undefined) {
                                currPageDATA.img_d = data["image-descriptions"][pageOrgName];
                            }
                        }
                        //image-descriptions
                        currPageDATA.hero = data.PAGES[hasSubs.main].hero;
                    }
                    else {
                        // no subpage
                        currPageDATA = data.PAGES[stateName];
                        currPageDATA.CTA = data.CTA;
                        currPageDATA.people_d = data["image-descriptions"]['people'];
                        console.log('what is the currPageDATA', currPageDATA);
                    }
                }
                return currPageDATA;
            };
            RXData.prototype.rootScopeOnready = function () {
                return this.defer.promise;
            };
            RXData.prototype.initObserver = function () {
                var _t = this;
                this.deferJobsData = this.$q.defer();
                var observer = Rx.Observer.create(function (x) {
                    var data = Rx.Observable.just(x).map(function (d) {
                        //console.log('this data is', d)
                        return d;
                    });
                    var currPageDATA;
                    var subscription = data.subscribe(function (x) {
                        // console.log('Next: %s', x);
                        if (x.errorFeed) {
                            console.log('errorFeed', x.message);
                            observer.error(x);
                        }
                        if (x.jobsDataJsonp !== undefined) {
                            _t.jobsDataJsonp = x.jobsDataJsonp;
                            console.log('equired x.jobsDataJsonp data', x.jobsDataJsonp);
                            if (_t.$rootScope.JobsData !== undefined) {
                                _t.$rootScope.JobsData = _t.jobsDataJsonp;
                            }
                        }
                        if (x.jsonData !== undefined) {
                            _t.ALL_DATA = x.jsonData;
                        }
                        if (x.type !== undefined) {
                            // managing current page/state "app.{pageName} pageOBJ"
                            if (x.type == 'initialize_data') {
                                if (x.jsonData !== undefined) {
                                    _t.ALL_DATA = x.jsonData;
                                    console.log('getting jsonData from x.type');
                                }
                                _t.$timeout(function () {
                                    var assertData = _t.assertData(x.has_sub, _t.ALL_DATA, x.stateName, observer, x.pageOrgName);
                                    if (!assertData) {
                                        //   _t.defer.reject(assertData)
                                        //  console.log('false data to resolve is.. ',assertData)
                                    }
                                    else {
                                        console.log('data to resolve is.. ', assertData);
                                        _t.defer.resolve(assertData);
                                    }
                                }, 300);
                            }
                        }
                        if (x.type !== undefined) {
                            // console.log('x data is', x);
                        }
                    }, function (err) {
                        console.log('Error: %s', err);
                    }, function () {
                        console.log('Completed, data passed to defer rootScopeOnready');
                    });
                }, function (err) {
                    _t.errorHandler.displayError(err);
                    console.log('Error: ' + err);
                }, function () {
                    console.log('Completed');
                });
                return observer;
            };
            return RXData;
        }());
        rxData.RXData = RXData;
        angular
            .module('app.rxData', []);
        angular
            .module('app.rxData')
            .service('rxData', RXData);
    })(rxData = app.rxData || (app.rxData = {}));
})(app || (app = {}));
